<template>
  <v-parallax src="@/assets/images/pages/reset-bg.png" height="100%">
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col align-self="center" cols="12" sm="6">
          <Logo/>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card outlined class="pa-3" height="500">
            <v-card-text class="pt-3">
              <h2 class="mb-10">Forgot password</h2>

              <v-form class="mb-15" ref="forgotForm" @submit.prevent="handleSubmitClick()">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Email address"
                      type="email"
                      v-model="forgotForm.email"
                      placeholder="adam@gmail.com"
                      :rules="[required('Email'), email('Email')]"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <router-link to="/signin">Sign-in</router-link>
                    <v-btn
                      absolute
                      small
                      right
                      class="mx-3 rounded-pill"
                      color="primary"
                      type="submit"
                      :loading="loading"
                    >Submit</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SEND_PASSWORD_RESET_LINK } from "@/store/_actiontypes";
import validations from "@/utils/validations"
import Logo from "@/components/common/Logo"

export default {
  components: {
    Logo,
  },
  data() {
    return {
      forgotForm: {
        email: "",
      },
      ...validations,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
    }),
  },
  mounted(){
    if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY")}`
      );
      plugin.async = true;
      document.head.appendChild(plugin);
    }
  },
  methods: {
    ...mapActions("account", [SEND_PASSWORD_RESET_LINK]),

    handleSubmitClick(){
      if (!this.$refs.forgotForm.validate()) return;
      
      if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
        grecaptcha.execute(this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY"), {action: 'reset_password'}).then((recaptcha_token) => {
          this.handleSubmit(recaptcha_token);
        });
      } else{
        this.handleSubmit(null);
      }
    },
    handleSubmit(recaptcha_token){
      const { email } = this.forgotForm;
      let reset_page_route = "reset-password/"
      this.SEND_PASSWORD_RESET_LINK({ email, reset_page_route, recaptcha_token }).then((res)=>{

      }).catch(err=>{

      });
    },
  },
};
</script>